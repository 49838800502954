.button1 {
	/* display: inline-block; */
	padding: 0.35em 1.5em;
	border: 0.1em solid #ffffff;
	/* margin: 0 0.3em 0.3em 0; */
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-align: center;
	transition: all 0.2s;
	background-color: #efb758;
	transform: scale(1.2);
	width: 140px;
	text-align: center;
}

.button1:hover {
	color: #efb758;
	background-color: #ffffff;
}

.buttons {
	width: 100%;
	transform: translateX(20px);
	margin: 0 auto;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ordering-container {
	height: 30vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 5;
}

.characters {
	width: 100vw;
	height: 20vh;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
}

.dropzone-container {
	height: 20vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.image-card {
	height: 100px;
	width: 100px;
}

.reorder-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
