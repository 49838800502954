.navbar {
	height: 10vh;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 5;
	width: 100vw;
}

.navbar h3 {
	color: white;
	font-size: 1rem;
	margin: 20px;
}

.logo {
	height: 10vh;
}

.sign-out-button {
	cursor: pointer;
	
}

.search-sign-out {
	margin-top: 3vh;
	display: flex;
	align-items: center;
}
