@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login {
	height: 100vh;
	width: 100vw;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow-y: hidden;
}

.login-logo {
	margin-top: 5vh;
	height: 10vh;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}

.login-container {
	margin-top: 10vh;
	background-color: white;

	width: 35vw;
	border-radius: 2%;
	padding: 3em;

}

#login-email {
	width: 30vw;
}

#login-password {
	width: 30vw;
}

#forgot-email {
	width: 30vw;
}

#forgot-password {
	width: 30vw;
}

a {
	text-decoration: none;
}

h6,
h5 {
	color: gray;
}

.sign-in-button {
	background-color: white;
	box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	border: 2px solid #efb758;
	padding: 3px 19px;
	line-height: 32px;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 50;
	color: #efb758;
	width: 10vw;
}

.login-create {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
/****************************************************************/
/* Shutter In Vertical */
.hvr-shutter-in-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #efb758;
	transition-property: color;
	transition-duration: 0.3s;
	border-radius: 20px;
}
.hvr-shutter-in-vertical:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	border-radius: 20px;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover,
.hvr-shutter-in-vertical:focus,
.hvr-shutter-in-vertical:active {
	color: white;
}
.hvr-shutter-in-vertical:hover:before,
.hvr-shutter-in-vertical:focus:before,
.hvr-shutter-in-vertical:active:before {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
}

/**************************************************************/

.spinner {
	width: 100px;
	height: 50px;
}

.incorrect {
	color: red;
}

@media only screen and (max-width: 700px) {
	.sign-in-button {
		-webkit-transform: scaleX(1.5);
		        transform: scaleX(1.5);
	}

	.login-container {
		height: 65vh;
		width: 50vw;
	}
}

.forgot {
	cursor: pointer;
}

.forgot-send-button {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	-webkit-transform: translateY(2vh);
	        transform: translateY(2vh);
}

.tickImage {
	width: 5px;
	height: 5px;
}

#capturedFont {
	color: rgb(6, 112, 6);
	text-align: center;
	border: 1px solid rgb(6, 112, 6);
	border-radius: 2px;
	padding: 3px;
}

#notCapturedFont {
	color: rgb(255, 21, 21);
	text-align: center;
	border: 1px solid rgb(255, 21, 21);
	border-radius: 2px;
	padding: 3px;
}

.button1 {
	/* display: inline-block; */
	padding: 0.35em 1.5em;
	border: 0.1em solid #ffffff;
	/* margin: 0 0.3em 0.3em 0; */
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-align: center;
	transition: all 0.2s;
	background-color: #efb758;
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
	width: 140px;
	text-align: center;
}

.button1:hover {
	color: #efb758;
	background-color: #ffffff;
}

.buttons {
	width: 100%;
	-webkit-transform: translateX(20px);
	        transform: translateX(20px);
	margin: 0 auto;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.ordering-container {
	height: 30vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 5;
}

.characters {
	width: 100vw;
	height: 20vh;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
}

.dropzone-container {
	height: 20vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.image-card {
	height: 100px;
	width: 100px;
}

.reorder-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.upload {
		background-color: #f1f1f1;
		height: 90vh;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

}

.capturedFont {
	color: rgb(6, 112, 6);
	text-align: center;
	border: 1px solid rgb(6, 112, 6);
	border-radius: 2px;
	padding: 3px;
	font-family: "Montserrat", sans-serif;
	width: 100px;
	height: 20px;
	font-size: 0.8em;
}

.grid {
	align-self: flex-start;
	margin: 40px;
	position: static;
}

.info {
	/* height: 80vh; */
	width: 30vw;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;

}

.infoVertical {
	/* display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: nowrap; */
	display: grid;	
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 30px 30px;
	gap: 30px 30px;
	align-items: flex-start;
	justify-content: space-evenly;

}

.slider {

	height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 40vw;

}

.container {
	width: 80vw;
	grid-row-gap: 40px;
	row-gap: 40px;
	display: flex;
	/* flex-direction: row;
	justify-content: space-evenly;
	align-items: center; */
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	align-items: center;


}

.containerOpen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 30px 30px;
	gap: 30px 30px;
	align-items: center;
}

.gallery {
		/* display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 1px 1px;
		grid-template-areas: ". ." ". ."; */
}

.thumbnails {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	-webkit-transform: translateY(-5vh);
	        transform: translateY(-5vh);
}

.picture-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.description {
	margin: 1vw;
	padding: 20px;
}

.type {
	/* background-color: red; */
	display: flex;
	/* flex-direction: column;
	justify-content: center; */
	align-items: center;
	-webkit-transform: translateY(-10vh);
	        transform: translateY(-10vh);
}

.photo-upload-container {
	height: 20%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;	
	justify-content: space-between;


}


.select {
	display: flex;
	flex-direction: column;
	margin: 20px;
	align-items: center;
}


.button1 {
	display: inline-block;
	padding: 0.35em 1.5em;
	border: 0.1em solid #ffffff;
	margin: 10 0.3em 0.3em 0;
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-align: center;
	transition: all 0.2s;
	background-color: #efb758;
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
	width: 100px;
}

.button1:hover {
	color: #efb758;
	background-color: #ffffff;
}

.buttons {
	align-self: center;
	display: flex;
	flex-direction: row;
	-webkit-transform: translateX(5vw);
	        transform: translateX(5vw);
}

.image-class {
	width: 30vw;
	height: 60vh;
}
.workspace {
	height: 90vh;
	background-color: #333;
	/* width: 100%; */
	/* overflow-y: auto; */
	overflow-x: hidden;
	overflow-y: scroll;
	font-family: "Montserrat", sans-serif;
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

.table-container {
	width: 100vw;
	margin: 0 auto;
	height: 90vh;
}

.modal-container {
}

.tab-panel {
	-webkit-transform: translate(-1.7vw, -2vh);
	        transform: translate(-1.7vw, -2vh);
}

.navbar {
	height: 10vh;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 5;
	width: 100vw;
}

.navbar h3 {
	color: white;
	font-size: 1rem;
	margin: 20px;
}

.logo {
	height: 10vh;
}

.sign-out-button {
	cursor: pointer;
	
}

.search-sign-out {
	margin-top: 3vh;
	display: flex;
	align-items: center;
}

body {
	/* overflow-y: hidden; */
	background-color: #333;
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

* {
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}

.App {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 9fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	height: 100vh;
}

.workspace-container {
	background-color: white;
	height: 90vh;
	width: 100%;
}

.navbar-container {
	background-color: black;
	height: 10vh;
}

.div1 {
	grid-area: 2 / 1 / 3 / 2;
}

/* @media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
} */

