.tickImage {
	width: 5px;
	height: 5px;
}

#capturedFont {
	color: rgb(6, 112, 6);
	text-align: center;
	border: 1px solid rgb(6, 112, 6);
	border-radius: 2px;
	padding: 3px;
}

#notCapturedFont {
	color: rgb(255, 21, 21);
	text-align: center;
	border: 1px solid rgb(255, 21, 21);
	border-radius: 2px;
	padding: 3px;
}
