@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

.workspace {
	height: 90vh;
	background-color: #333;
	/* width: 100%; */
	/* overflow-y: auto; */
	overflow-x: hidden;
	overflow-y: scroll;
	font-family: "Montserrat", sans-serif;
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

.table-container {
	width: 100vw;
	margin: 0 auto;
	height: 90vh;
}

.modal-container {
}

.tab-panel {
	transform: translate(-1.7vw, -2vh);
}
