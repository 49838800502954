@import "~react-image-gallery/styles/css/image-gallery.css";	

.upload {
		background-color: #f1f1f1;
		height: 90vh;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

}

.capturedFont {
	color: rgb(6, 112, 6);
	text-align: center;
	border: 1px solid rgb(6, 112, 6);
	border-radius: 2px;
	padding: 3px;
	font-family: "Montserrat", sans-serif;
	width: 100px;
	height: 20px;
	font-size: 0.8em;
}

.grid {
	align-self: flex-start;
	margin: 40px;
	position: static;
}

.info {
	/* height: 80vh; */
	width: 30vw;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;

}

.infoVertical {
	/* display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: nowrap; */
	display: grid;	
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 30px 30px;
	align-items: flex-start;
	justify-content: space-evenly;

}

.slider {

	height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 40vw;

}

.container {
	width: 80vw;
	row-gap: 40px;
	display: flex;
	/* flex-direction: row;
	justify-content: space-evenly;
	align-items: center; */
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	align-items: center;


}

.containerOpen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 30px 30px;
	align-items: center;
}

.gallery {
		/* display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 1px 1px;
		grid-template-areas: ". ." ". ."; */
}

.thumbnails {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	transform: translateY(-5vh);
}

.picture-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.description {
	margin: 1vw;
	padding: 20px;
}

.type {
	/* background-color: red; */
	display: flex;
	/* flex-direction: column;
	justify-content: center; */
	align-items: center;
	transform: translateY(-10vh);
}

.photo-upload-container {
	height: 20%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;	
	justify-content: space-between;


}


.select {
	display: flex;
	flex-direction: column;
	margin: 20px;
	align-items: center;
}


.button1 {
	display: inline-block;
	padding: 0.35em 1.5em;
	border: 0.1em solid #ffffff;
	margin: 10 0.3em 0.3em 0;
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration: none;
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	color: #ffffff;
	text-align: center;
	transition: all 0.2s;
	background-color: #efb758;
	transform: scale(1.2);
	width: 100px;
}

.button1:hover {
	color: #efb758;
	background-color: #ffffff;
}

.buttons {
	align-self: center;
	display: flex;
	flex-direction: row;
	transform: translateX(5vw);
}

.image-class {
	width: 30vw;
	height: 60vh;
}