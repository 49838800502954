@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
	/* overflow-y: hidden; */
	background-color: #333;
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

* {
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}

.App {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 9fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	height: 100vh;
}

.workspace-container {
	background-color: white;
	height: 90vh;
	width: 100%;
}

.navbar-container {
	background-color: black;
	height: 10vh;
}

.div1 {
	grid-area: 2 / 1 / 3 / 2;
}

/* @media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
} */
